export const ENVIRONMENT = {
  PROD: 'PROD',
  STAGE: 'STAGE',
  DEV: 'DEV',
  QA: 'QA',
  PREPROD: 'PREPROD'
};

export const ACTIONS = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SET_COMPANY_PROFILE: 'SET_COMPANY_PROFILE',
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  SET_SELECTED_PARTNERSHIP: 'SET_SELECTED_PARTNERSHIP',
  SET_LANDING_PAGE_PARTNERSHIP: 'SET_LANDING_PAGE_PARTNERSHIP'
};

export const policyStatus = {
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  OVERDUE: 'OVERDUE'
};

export const receiptStatus = {
  applied: 'APPLIED',
  notApplied: 'NOT_APPLIED'
};

// payment status values as received from backened
export const PAYMENT_STATUS = {
  PAID: 'PAID',
  PARTIAL_PAID: 'PARTIAL_PAID',
  NOT_PAID: 'NOT_PAID',
  IN_PROCESS: 'IN_PROCESS',
  OVERDUE: 'OVERDUE',
  OPEN: 'OPEN',
  MISSING: 'MISSING'
};

// Invoice status to display to users
export const invoiceStatus = {
  [PAYMENT_STATUS.PAID]: 'Full Paid',
  [PAYMENT_STATUS.PARTIAL_PAID]: 'Partial Paid',
  [PAYMENT_STATUS.NOT_PAID]: 'Pending',
  [PAYMENT_STATUS.OPEN]: 'Pending',
  [PAYMENT_STATUS.IN_PROCESS]: 'In Process',
  [PAYMENT_STATUS.OVERDUE]: 'Overdue'
};

// css classes for each payment status
export const paymentCssClass = {
  [PAYMENT_STATUS.PAID]: 'paid',
  [PAYMENT_STATUS.PARTIAL_PAID]: 'partial',
  [PAYMENT_STATUS.NOT_PAID]: 'pending',
  [PAYMENT_STATUS.OPEN]: 'pending',
  [PAYMENT_STATUS.IN_PROCESS]: 'in-process',
  [PAYMENT_STATUS.OVERDUE]: 'overdue',
  [PAYMENT_STATUS.MISSING]: 'missing'
};

export const INSTALLMENT_TYPE = {
  NO_EMI: 'NO',
  SINGLE_EMI: 'SINGLE',
  MULTIPLE_EMI: 'MULTIPLE'
};

export const FORM_TYPES = {
  SELECT: 'select',
  INPUT_TEXT: 'input',
  DATE: 'date',
  BUTTON: 'button'
};

export const reportingStatusClases = {
  PAID: 'settled',
  PARTIAL_PAID: 'due',
  NOT_PAID: 'notpaid',
  MISSING: 'MISSING'
};

export const pennyDropStatusMap = {
  COMPLETED: 'COMPLETED',
  INITIATED: 'INITIATED',
  VERIFICATION_IN_PROGRESS: 'VERIFICATION_IN_PROGRESS',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  SUCCESS: 'SUCCESS'
};

export const PARTNERSHIP_TYPE = {
  VENDOR: 'VENDOR',
  PARTNER: 'PARTNER'
};
export const validationTextMap = {
  COMPLETED: 'Has been transferred to verify the provided account details',
  INITIATED: 'is being transferred to verify the provided account detail',
  VERIFICATION_IN_PROGRESS: 'is being transferred to verify the provided account details',
  VERIFICATION_FAILED: 'Bank verification is failed, please try again'
};

export const PARTNERSHIPS = {
  DTH: 'DTH',
  IPTV: 'IPTV',
  FWA: 'FWA'
};
